@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=VT323&family=Zen+Dots&display=swap');
body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
    overflow-y: auto;
    align-items: center;
    background: #232323;
    /* background: linear-gradient(249deg, rgb(36, 36, 36), black, #000000, rgb(39, 39, 39)); */
    /* -webkit-animation: gradient 20s ease infinite;*/
    animation: gradient 20s ease-in-out infinite;
    background-size: 400% 100%;
    height: 100vh;
    display:block
}

::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
::selection {
    background-color: #ececec;
    color: black;
}

.navbar {
    padding: 0 15%;
    margin: 0 0 14% 0;
    width: 70%;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    backdrop-filter: blur(10px);
    background-color: rgb(255 255 255 / 92%);
}

.logo {
    list-style-type: none;
}

a {
    color: black;
    padding: 10px;
    text-decoration: none;
    font-weight: 400;
}
a:hover {
    color: white;
    padding: 10px;
    font-weight: 600;
    background: #00a6ff;
    transition: 0.2s cubic-bezier(0, 0, 0.32, 0.99) 100ms;
}
.Pages_Contact a {
    color: #00a6ff;
}
.Pages_Contact a:hover {
    color: white;
}

h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    padding: 0px 0px 8px 0px;
    display: inline-block;
    color: white;
    margin: 0;
}

.title::before {
    content: "";
    position: absolute;
    left: -22px;
    top: 16px;
    height: 16px;
    width: 8px;
    background-color: #00a6ff;
}
.Page_Home h1 {
    margin: 0 20px 0 -60px;
    padding-left: 80px;
}
span.blue{
    color: #00a6ff;
}

h2{
    font-weight: 600;
    color: white;
    font-size: 20px;
    line-height: 36px;
}
.footer h2 {
    font-size: 16px;
    color: black;
}

h3{
    font-weight: 300;
    color: white;
    font-size: 16px;
    line-height: 24px;
}
.footer h3 {
    font-size: 16px;
    color: black;
}
.introduction h3 {
    font-weight: 600;
    font-size: 16px;
}

.tabbar{
    position: absolute;
    right: 15%;
}
ul.tabbar {
    display: inherit;
    text-align: center;
    list-style-type: none;
}
.tabbar li {
    font-size: 16px;
    width: 140px;
}

.content {
    align-items: center;
    margin: 0 15%;
    position: absolute;
    width: 70%;
}

.Page_Home {
    position: inherit;
    width: 100%;
    padding: 30% 0;
}
.Page_Strategy {
    position: inherit;
    top: 110vh;
    width: 100%;
}
.Page_About {
    position: inherit;
    top: 220vh;
}
.Pages_Contact {
    position: inherit;
    top: 350vh;
}

.infotext {
    top: 37%;
    z-index: 10;
    float: left;
    width: 50%;
}

.infotext_chart
 {
    top: 37%;
    z-index: 10;
    float: left;
    width: 100%;
}
.BG_Strategy .infotext {
    width: 100%;
}
.infotext img {
    width: 60px;
    margin-bottom: -2px;
}

.infoimage img {
    position: absolute;
    width: 50%;
    padding: 5%;
    top: 50%;
    transform: translateY(-50%);
}


.slide-arrow {
    position: absolute;
    top: calc(50% + 40px);
    transform: translateY(-50%);
    cursor: pointer;
}
.prev-arrow {
    left: -80px;
    background: none;
    border: 0px solid;
    opacity: 0.5;
}
.prev-arrow:hover {
    opacity: 1;
}
.prev-arrow img {
    height: 40px;
}
.next-arrow {
    right: -80px;
    background: none;
    border: 0px solid;
    opacity: 0.5;
}
.next-arrow:hover {
    opacity: 1;
}
.next-arrow img {
    height: 40px;
}

.slider-content {
    display: flex;
    overflow-x: scroll;
    width: 100vw;
    height: 76vh;
    float: left;
    margin: 0 -15vw;
    padding: 2vh 0;
}
.chart {
    height: 75vh;
    width: 70vw;
    margin: 0 15vw;
    border: 1px solid #ffffff5e;
}
.chart:hover {
    transition: 0.2s cubic-bezier(0, 0, 0.32, 0.99) 100ms;
    box-shadow: 0px 0px 20px #ffffff6b;
}
.describe {
    color: white;
    padding: 0px 30px;
}
.name {
    width: 70vw;
}
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 0.05em solid white;
    padding: 8px;
    text-align: left;
    width: 33.3%;
}
.important_number{
    line-height: 32px;
    text-decoration: underline;
    text-decoration-color: #00a6ff;
    text-decoration-thickness: 4px;
}
.datachart {
    width: calc(100% - 60px);
    margin: 30px;
    height: calc(70vh - 175px);
    border: 1px solid white;
}
.text-overlay {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: #05a6ff;
    padding: 0px 20px;
}

.aboutus {
    display: inline-block;
    margin: 0 -5%;
    overflow-x: auto;
}
.intromodal {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 23%;
    padding: 20px 5%;
    text-align: center;
}
.intromodal img {
    width: 100%;
    border-radius: 5%;
}
.introduction {
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 10px 20px;
    background-color: rgb(1 167 255 / 90%);
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
}
.intromodal:hover .introduction {
    opacity: 1;
}

.footer {
    position: inherit;
    align-items: center;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    bottom: -455vh;
    width: 144%;
    margin-left: -22%;
}
.address {
    width: 70%;
    border-bottom: 0.5px black solid;
    padding: 10px 0;
}
.footer ul {
    display: flex;
    align-items: center;
    width: 89%;
    text-align: center;
    padding: 16px 15%;
    margin-left: 2%;
}
.footer li {
    color: rgb(0, 0, 0);
    text-decoration: none;
    list-style-type: none;
    width: 33%;
    font-weight: 600;
}
.finally {
    width: 100%;
    text-align: center;
    background-color: #00000030;
}